import React from "react";
import IMAGE1 from './../assets/therger.png'
import IMAGE2 from './../assets/dasdsd.png'
import IMAGE3 from './../assets/fgdhty.png'
import IMAGE4 from './../assets/ZSddrrth.png'
import IMAGE5 from './../assets/sdfdg.png'
import IMAGE6 from './../assets/fhgyjty.png'
import { useNavigate } from "react-router-dom";
const MobileFooter = ({ header, body, button }) => {

  return (
    <>
      <div className="footer-new">
      </div>
      <div className="svg-icons">
        <h2>DISCOVER</h2>
        <div className="svg-icons-wrapper">
        <div className="svg-element">
            <img onClick={() => { window.location.replace("https://x.com/OCVCrypto?t=HcXTJbAklAM37W8oo-_1Ow&s=09") }} src={IMAGE6} />
          </div>
          <div className="svg-element">
            <img onClick={() => { window.location.href = "https://www.linkedin.com/company/one-crypto-ventures" }} src={IMAGE2} />
          </div>
          <div className="svg-element">
            <img onClick={() => { window.location.replace("https://www.notion.so/onecryptoventures/Introducing-One-Crypto-Ventures-dd36645a57db42c1890c4d1bb3244227?pvs=4") }} src={IMAGE5} />
          </div>
       
          <div className="svg-element">
            <img onClick={() => { window.location.href = "https://medium.com/@onecryptoventures" }} src={IMAGE1} />

          </div>
      
          <div onClick={() => { window.location.replace("mailto:projects@onecryptoventures.io") }} className="svg-element">
            <img onClick={() => { window.location.replace("mailto:projects@onecryptoventures.io") }} src={IMAGE4} />
          </div>
        
          {/* <div className="svg-element">
            <img onClick={() => { window.location.href = "https://t.me/+1x3KYDNSiAszYzRk" }} src={IMAGE3} />
          </div> */}
         
       
        </div>

      </div>
      <div className="extra-footer-part">

      </div>


    </>
  );
};

export default MobileFooter;
