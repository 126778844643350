import React from "react";

const GovAdvNew = ({ header, body, button }) => {
    
  return (
   <div className="gov-adv-new">
    <div onClick={()=>{
    window.location.replace("https://t.me/+1x3KYDNSiAszYzRk")
    }} className="desktop-gov-link"></div>
   </div>
  );
};

export default GovAdvNew;
