import React from "react";
import Video from "../assets/hrightughriugt11111.mp4";
const VideoSection = ({ header, body, button }) => {
  return (
    <div className="video-section">

<video  style={{width:"100%",margin:"auto"}} src={Video} controls autoPlay loop muted />
    </div>
  );
};

export default VideoSection;
