import React, { useEffect, useState } from "react";


const MobileTrustedBySection = () => {
    return (
        <>
             <div className="trusted-by-2-mobile"></div>
        </>
    );
};

export default MobileTrustedBySection;
