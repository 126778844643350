import React from "react";

const TrustedBySection = ({ header, body, button }) => {
    
  return (
   <div className="trusted-by-2"></div>
  );
};

export default TrustedBySection;
