import React, { useEffect, useState } from "react";
import Video from "../assets/video.mp4";
import { useNavigate } from "react-router-dom";
import LOGO from '../assets/logo.png'

const Services2 = () => {
    return (
        <>
            <div className="services-new-container">
                <div className="service-new-title"></div>
                <div className="service-new-content">
                    <div className="service-new-content-ind"></div>
                    <div className="service-new-content-ind3"></div>
                </div>
                <div className="service-new-content">
                    <div className="service-new-content-ind2"></div>
                    <div className="service-new-content-ind4"></div>
             
                </div>

            </div>

        </>
    );
};

export default Services2;
