import React from "react";

const Articles = ({ header, body, button }) => {
    
  return (
   <div className="articles-section">
     <div  onClick={()=>{
      window.location.replace("https://www.linkedin.com/posts/one-crypto-ventures_our-recent-vip-black-tie-soir%C3%A9e-in-dubai-activity-7191102485887889408-444a?utm_source=share&utm_medium=member_desktop")
    }} className="desktop-article-1"></div>
    <div onClick={()=>{
      window.location.replace("https://medium.com/@onecryptoventures/3-essential-strategies-to-build-on-web3-marketing-2c9263f54060")
    }} className="desktop-article-2"></div>
    <div onClick={()=>{
      window.location.replace("https://medium.com/@onecryptoventures/7-must-use-tactics-to-propel-your-crypto-project-to-the-top-ca283560e0f1")
    }} className="desktop-article-3"></div>
     <div onClick={()=>{
      window.location.replace("https://www.linkedin.com/company/one-crypto-ventures")
    }} className="desktop-article-4"></div>
   </div>
  );
};

export default Articles;
