import React, { useEffect, useState } from "react";


const ReachOutSection = () => {
    return (
        <>
            <div className="links-section">
                <div onClick={()=>{
                    window.location.replace("http://tinyurl.com/2p9k8cpy")
                }} className="link-section-one">

                </div>
                <div onClick={()=>{
                    window.location.replace("https://www.notion.so/onecryptoventures/Introducing-One-Crypto-Ventures-dd36645a57db42c1890c4d1bb3244227?pvs=4")
                }} className="link-section-two">

                </div>
            </div>
        </>
    );
};

export default ReachOutSection;
