import React from "react";

const MobileGovAdv = ({ header, body, button }) => {
    
  return (
   <div className="gov-adv-new-mobile">
    <div onClick={()=>{
    window.location.replace("https://t.me/+1x3KYDNSiAszYzRk")
    }} className="mobile-gov-link-1"></div>
   </div>
  );
};

export default MobileGovAdv;
